import React from 'react';
import { useCurrency } from '../../contexts/CurrencyContext';
import useOrderData from '../../hooks/useOrderData';
import { RefreshCw, TrendingUp, TrendingDown } from 'lucide-react';
import { formatPrice } from '../../utils/formatters';

const TradeStatsDisplay = () => {
  const { currency } = useCurrency();
  const { orderData, loading, error, refreshData } = useOrderData();

  // Calculate statistics from order data
  const calculateStats = () => {
    if (!orderData || orderData.length === 0) {
      return {
        totalTrades: 0,
        profitableTrades: 0,
        lossTrades: 0,
        winRate: 0,
        averageProfit: 0,
        averageLoss: 0,
        bestTrade: { pnl: 0, percent: 0 },
        worstTrade: { pnl: 0, percent: 0 },
        totalPnL: 0
      };
    }

    // Filter completed trades
    const completedTrades = orderData.filter(order => order.timestamp_exit);

    if (completedTrades.length === 0) {
      return {
        totalTrades: 0,
        profitableTrades: 0,
        lossTrades: 0,
        winRate: 0,
        averageProfit: 0,
        averageLoss: 0,
        bestTrade: { pnl: 0, percent: 0 },
        worstTrade: { pnl: 0, percent: 0 },
        totalPnL: 0
      };
    }

    // Calculate stats
    const profitableTrades = completedTrades.filter(order => order.realised_pnl > 0);
    const lossTrades = completedTrades.filter(order => order.realised_pnl <= 0);

    const winRate = (profitableTrades.length / completedTrades.length) * 100;

    const averageProfit = profitableTrades.length > 0
      ? profitableTrades.reduce((sum, order) => sum + order.realised_pnl, 0) / profitableTrades.length
      : 0;

    const averageLoss = lossTrades.length > 0
      ? lossTrades.reduce((sum, order) => sum + order.realised_pnl, 0) / lossTrades.length
      : 0;

    const bestTrade = completedTrades.reduce((best, order) => {
      const percent = (order.realised_pnl / order.value) * 100;
      return order.realised_pnl > best.pnl ? { pnl: order.realised_pnl, percent } : best;
    }, { pnl: -Infinity, percent: 0 });

    const worstTrade = completedTrades.reduce((worst, order) => {
      const percent = (order.realised_pnl / order.value) * 100;
      return order.realised_pnl < worst.pnl ? { pnl: order.realised_pnl, percent } : worst;
    }, { pnl: Infinity, percent: 0 });

    const totalPnL = completedTrades.reduce((sum, order) => sum + order.realised_pnl, 0);

    return {
      totalTrades: completedTrades.length,
      profitableTrades: profitableTrades.length,
      lossTrades: lossTrades.length,
      winRate,
      averageProfit,
      averageLoss,
      bestTrade,
      worstTrade,
      totalPnL
    };
  };

  const stats = calculateStats();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-48">
        <div className="w-6 h-6 border-3 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-3 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400 text-sm">
        {error}
      </div>
    );
  }

  if (!orderData || orderData.length === 0) {
    return <div className="text-slate-400">No trading statistics available for {currency.id}</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="text-sm text-slate-300 font-medium">
          {currency.id} Trading Performance
        </div>
        <button
          onClick={refreshData}
          className="p-1 rounded hover:bg-slate-700 transition-colors"
          title="Refresh data"
        >
          <RefreshCw className="h-4 w-4 text-slate-400" />
        </button>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div className="bg-slate-800/50 rounded-md p-3">
          <div className="text-xs text-slate-400 mb-1">Win Rate</div>
          <div className={`text-lg font-medium ${stats.winRate >= 50 ? 'text-green-400' : 'text-red-400'}`}>
            {stats.winRate.toFixed(1)}%
          </div>
        </div>

        <div className="bg-slate-800/50 rounded-md p-3">
          <div className="text-xs text-slate-400 mb-1">Total PnL</div>
          <div className={`text-lg font-medium ${stats.totalPnL >= 0 ? 'text-green-400' : 'text-red-400'}`}>
            {stats.totalPnL >= 0 ? '+' : ''}{stats.totalPnL.toFixed(4)}
          </div>
        </div>

        <div className="bg-slate-800/50 rounded-md p-3">
          <div className="text-xs text-slate-400 mb-1">Trades</div>
          <div className="flex items-center">
            <div className="text-lg font-medium text-slate-100 mr-2">
              {stats.totalTrades}
            </div>
            <div className="flex gap-2 text-xs">
              <div className="flex items-center text-green-400">
                <TrendingUp className="h-3 w-3 mr-1" /> {stats.profitableTrades}
              </div>
              <div className="flex items-center text-red-400">
                <TrendingDown className="h-3 w-3 mr-1" /> {stats.lossTrades}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-slate-800/50 rounded-md p-3">
          <div className="text-xs text-slate-400 mb-1">Avg P/L</div>
          <div className="flex flex-col">
            <span className="text-xs text-green-400">
              +{formatPrice(stats.averageProfit)}
            </span>
            <span className="text-xs text-red-400">
              {formatPrice(stats.averageLoss)}
            </span>
          </div>
        </div>

        <div className="bg-slate-800/50 rounded-md p-3 col-span-2">
          <div className="text-xs text-slate-400 mb-1">Best/Worst Trade</div>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <div className="text-xs text-green-400">
                +{formatPrice(stats.bestTrade.pnl)} ({stats.bestTrade.percent.toFixed(2)}%)
              </div>
            </div>
            <div>
              <div className="text-xs text-red-400">
                {formatPrice(stats.worstTrade.pnl)} ({stats.worstTrade.percent.toFixed(2)}%)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeStatsDisplay;