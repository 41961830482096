import React, { createContext, useContext, useState } from 'react';

// Verfügbare Kryptowährungen
export const AVAILABLE_CURRENCIES = [
  { id: 'BTC', name: 'Bitcoin', color: '#F7931A' },
  { id: 'ETH', name: 'Ethereum', color: '#627EEA' },
  { id: 'BNB', name: 'BNB', color: '#F3BA2F' },
  { id: 'DOGE', name: 'Dogecoin', color: '#C2A633' },
  { id: 'SOL', name: 'Solana', color: '#00FFA3' },
  { id: 'XRP', name: 'XRP', color: '#23292F' }
];

// Erstelle den Kontext
const CurrencyContext = createContext();

// Provider-Komponente
export function CurrencyProvider({ children }) {
  const [currency, setCurrency] = useState(AVAILABLE_CURRENCIES[0]); // Standardmäßig BTC

  const updateCurrency = (currencyId) => {
    const newCurrency = AVAILABLE_CURRENCIES.find(c => c.id === currencyId);
    if (newCurrency) {
      setCurrency(newCurrency);
    }
  };

  // Werte, die der Kontext bereitstellt
  const value = {
    currency,
    updateCurrency,
    availableCurrencies: AVAILABLE_CURRENCIES
  };

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
}

// Custom Hook zum einfachen Zugriff auf den Kontext
export function useCurrency() {
  const context = useContext(CurrencyContext);
  if (context === undefined) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
}