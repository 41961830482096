// Dynamisch die API-Basis-URL basierend auf der Umgebung ermitteln
const API_BASE_URL = (() => {
  // Prüfen, ob die App lokal läuft oder auf dem Server
  const isLocalhost =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1' ||
    window.location.hostname.includes('192.168.');

  // Lokalen Server verwenden, wenn die App lokal läuft, sonst leeren String für relative Pfade
  return isLocalhost ? 'http://localhost:8000' : '';
})();


// Dynamische Endpunkte je nach Währung
const getEndpoints = (currency = 'BTC') => ({
  TRADING_DATA: `/api/${currency}/trading-data/?days=14`,
  CURRENT_PRICE: `/api/${currency}/current-price/`,
  VOLUME_24H: `/api/${currency}/24h-volume/`,
  MACD_TREND: `/api/${currency}/macd-trend/`,
  ORDERS: `/api/${currency}/orders/`,
  HEALTH: '/api/health'
});

// API Service Funktionen
const apiService = {
  // Trading Daten laden
  async fetchTradingData(currency = 'BTC') {
    try {
      const response = await fetch(`${API_BASE_URL}${getEndpoints(currency).TRADING_DATA}`);
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching trading data:', error);
      throw error;
    }
  },

  // Aktuellen Preis laden
  async fetchCurrentPrice(currency = 'BTC') {
    try {
      const response = await fetch(`${API_BASE_URL}${getEndpoints(currency).CURRENT_PRICE}`);
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching current price:', error);
      throw error;
    }
  },

  // 24h Volumen laden
  async fetch24hVolume(currency = 'BTC') {
    try {
      const response = await fetch(`${API_BASE_URL}${getEndpoints(currency).VOLUME_24H}`);
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching 24h volume:', error);
      throw error;
    }
  },

  // MACD Trend laden
  async fetchMacdTrend(currency = 'BTC') {
    try {
      const response = await fetch(`${API_BASE_URL}${getEndpoints(currency).MACD_TREND}`);
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching MACD trend:', error);
      throw error;
    }
  },

  // Order Daten laden (mit Filter für neuere Orders)
  async fetchOrderData(currency = 'BTC') {
    try {
      const response = await fetch(`${API_BASE_URL}${getEndpoints(currency).ORDERS}`);
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const jsonData = await response.json();

      // Filter orders nach dem 13. Februar 2025
      const filteredData = jsonData.filter(order => {
        const entryDate = new Date(order.timestamp_entry);
        return entryDate > new Date('2025-02-13T00:00:00');
      });

      return filteredData;
    } catch (error) {
      console.error('Error fetching order data:', error);
      return [];
    }
  },

  // NEUE METHODE: Alle Order Daten laden (ohne Filter)
  async fetchAllOrderData(currency = 'BTC') {
    try {
      const response = await fetch(`${API_BASE_URL}${getEndpoints(currency).ORDERS}`);
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      return await response.json(); // Alle Orders ohne Filter zurückgeben
    } catch (error) {
      console.error('Error fetching all order data:', error);
      return [];
    }
  },

  // Server-Status prüfen
  async checkServerHealth() {
    try {
      const response = await fetch(`${API_BASE_URL}${getEndpoints().HEALTH}`);
      return response.ok;
    } catch (error) {
      console.error('Error checking server health:', error);
      return false;
    }
  }
};

export default apiService;