import React from 'react';
import { Link } from 'react-router-dom';
import {
  LineChart,
  ArrowRight,
  Code2,
  Laptop,
  Code,
  Database,
  Briefcase
} from 'lucide-react';

const LandingPage = () => {
  return (
    // Container für die gesamte Landing Page
    <div className="min-h-screen bg-slate-900">
      {/* Hero Section mit Bild und Intro */}
      <section className="bg-gradient-to-b from-gray-900 via-gray-800 bg-slate-800/50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center gap-8">
            {/* Bild-Container */}
            <div className="w-48 h-56 md:w-64 md:h-80 overflow-hidden rounded-full">
              <img
                  src="/images/profile.jpg"
                  alt="Profile"
                  className="w-full h-full object-cover object-center "
              />
            </div>

            {/* Text-Container */}
            <div className="flex-1 text-center md:text-left">
              <h1 className="text-4xl font-bold text-slate-100 mb-4">
                Dennis Hohmeier
              </h1>
              <p className="text-xl text-slate-300 mb-6">
                Wirtschaftsinformatik-Student | Business Intelligence | Softwareentwicklung
              </p>
              <div className="text-slate-400 max-w-2xl space-y-4">
                <p className="leading-relaxed">
                  Als Student der Wirtschaftsinformatik im 6. Semester verbinde ich fundierte analytische
                  Fähigkeiten mit praktischer Programmiererfahrung in Python, C#, Tailwind CSS und mehr.
                </p>

                <p className="leading-relaxed">
                  Meine Leidenschaft liegt in der Entwicklung datengetriebener Anwendungen, die Business
                  Intelligence und moderne Webtechnologien vereinen.
                </p>
                </div>
                <div className="mt-6 bg-blue-500/10 border border-blue-500/20 p-4 rounded-lg">
                  <p className="text-blue-300 font-medium">
                    ✨ Aktuell suche ich einen Praktikumsplatz für meine Bachelorarbeit
                  </p>
                </div>
              </div>
            </div>
          </div>
      </section>

      {/* Qualifikationen Section */}
      <section className="bg-slate-800/50 py-16 border-t border-b border-slate-600/50 ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-slate-100 mb-8 text-center">
            Qualifikationen
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Programmierung */}
            <div className="bg-slate-700/50 rounded-lg p-6">
              <div className="flex items-center gap-3 mb-4">
                <Code className="h-6 w-6 text-blue-400"/>
                <h3 className="text-lg font-semibold text-slate-100">
                  Programmierung
                </h3>
              </div>
              <p className="text-slate-300">
                Umfangreiche Kenntnisse in Python, Java und C#
              </p>
            </div>

            {/* Erfahrung */}
            <div className="bg-slate-700/50 rounded-lg p-6">
              <div className="flex items-center gap-3 mb-4">
                <Briefcase className="h-6 w-6 text-green-400" />
                <h3 className="text-lg font-semibold text-slate-100">
                  Erfahrung
                </h3>
              </div>
              <p className="text-slate-300">
                Geübt im Umgang mit Frameworks wie React, Tailwind und Pandas
              </p>
            </div>

            {/* Business Intelligence */}
            <div className="bg-slate-700/50 rounded-lg p-6">
              <div className="flex items-center gap-3 mb-4">
                <Database className="h-6 w-6 text-purple-400" />
                <h3 className="text-lg font-semibold text-slate-100">
                  Business Intelligence
                </h3>
              </div>
              <p className="text-slate-300">
                ETL Praktiken und Data Warehouse Modellierungen aus dem Studium
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Projekte Section */}
      <section className="bg-gradient-to-b from-slate-800/50 via-gray-800 to-gray-900 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-slate-100 mb-12 text-center">
            Meine Projekte
          </h2>

          {/* Projekt Grid */}
          <div className="flex justify-center">
            {/* Trading Bot Projekt Card */}
            <div className="group relative max-w-sm">
              <div className="bg-slate-800 rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl">
                <div className="h-48 bg-blue-500/10 overflow-hidden">
                  <img
                      src="/images/Preview_Bot.png"
                      alt="Trading System Überblick"
                      className="w-full object-cover object-top rounded-t-lg"
                      style={{maxHeight: "100%"}}
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-slate-100 mb-2">
                    Trading Bot
                  </h3>
                  <p className="text-slate-400 mb-4">
                  Automatisiertes Trading System mit MACD-Strategie und
                    Performance-Tracking.
                  </p>
                  <Link
                    to="/trading-bot"
                    className="inline-flex items-center text-blue-400 hover:text-blue-300"
                  >
                    Projekt ansehen
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;