import { useState, useEffect } from 'react';
import apiService from '../services/apiService';
import { useCurrency } from '../contexts/CurrencyContext';

/**
 * Custom Hook für das Laden und Verwalten von Trading-Daten
 * @param {number} refreshInterval - Intervall für automatische Aktualisierung in ms (Standard: 120000ms = 2min)
 * @returns {Object} - Objekt mit Daten, Ladestatus, Fehler und einer manuellen Aktualisierungsfunktion
 */
const useTradingData = (refreshInterval = 120000) => {
  const { currency } = useCurrency();
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const tradingData = await apiService.fetchTradingData(currency.id);
      setChartData(tradingData.data);
      setLastUpdate(tradingData.last_update);
      setError(null);
    } catch (err) {
      console.error(`Error fetching trading data for ${currency.id}:`, err);
      setError(`Failed to fetch trading data for ${currency.id}: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Daten laden, wenn sich die Währung ändert
  useEffect(() => {
    fetchData();

    // Regelmäßige Aktualisierung einrichten, wenn ein Intervall angegeben ist
    let intervalId;
    if (refreshInterval > 0) {
      intervalId = setInterval(fetchData, refreshInterval);
    }

    // Aufräumen beim Unmounten der Komponente
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [currency.id, refreshInterval]);

  return {
    chartData,
    loading,
    error,
    lastUpdate,
    refreshData: fetchData
  };
};

export default useTradingData;