import React from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/common/ui/card";
import {
  Mail,
  Github,
  MapPin,
  Calendar,
  ArrowRight,
  LinkedinIcon,
  Copy
} from 'lucide-react';

const Contact = () => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Kopiert!');
      })
      .catch(err => {
        console.error('Fehler beim Kopieren: ', err);
      });
  };

  return (
    <div className="min-h-screen bg-slate-900">
      {/* Hero Section */}
      <section className="py-12 border-b border-slate-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-10">
            <h1 className="text-3xl font-bold text-slate-100 mb-4">
              Kontakt
            </h1>
            <p className="text-slate-300 max-w-2xl mx-auto">
              Du möchtest mit mir in Kontakt treten oder hast Fragen zu meinen Projekten?
              Hier findest du alle wichtigen Kontaktinformationen.
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Linke Spalte - Kontakt Cards */}
            <div className="space-y-6">
              {/* Telefon-Karte */}

              {/* Email-Karte */}
              <Card className="hover:border-blue-500/30 transition-colors duration-300">
                <CardHeader className="flex flex-row items-center space-y-0 pb-2">
                  <div className="rounded-full bg-green-500/10 p-2 mr-4">
                    <Mail className="h-5 w-5 text-green-400" />
                  </div>
                  <CardTitle className="text-slate-100">E-Mail</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex items-center justify-between">
                    <p className="text-slate-300">dennis.hohmeier1404@gmail.com</p>
                    <button
                      onClick={() => copyToClipboard('dennis.hohmeier1404@gmail.com')}
                      className="text-green-400 hover:text-green-300"
                      aria-label="Kopieren"
                    >
                      <Copy className="h-4 w-4" />
                    </button>
                  </div>
                </CardContent>
              </Card>

              {/* GitHub-Karte */}
              <Card className="hover:border-blue-500/30 transition-colors duration-300">
                <CardHeader className="flex flex-row items-center space-y-0 pb-2">
                  <div className="rounded-full bg-purple-500/10 p-2 mr-4">
                    <Github className="h-5 w-5 text-purple-400" />
                  </div>
                  <CardTitle className="text-slate-100">GitHub</CardTitle>
                </CardHeader>
                <CardContent>
                  <a
                    href="https://github.com/DennisH03"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-slate-300 hover:text-purple-400 flex items-center"
                  >
                    @DennisH03
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </a>
                </CardContent>
              </Card>
            </div>

            {/* Mittlere Spalte - Verfügbarkeit */}
            <div className="md:col-span-2">
              <Card className="h-full">
                <CardHeader>
                  <CardTitle className="text-xl font-semibold text-slate-100">
                    Verfügbarkeit für Praktika
                  </CardTitle>
                  <CardDescription className="text-slate-400">
                    Ich bin auf der Suche nach einem Praktikumsplatz für meine Bachelorarbeit
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                  {/* Verfügbarkeit */}
                  <div className="flex items-start space-x-4">
                    <div className="bg-blue-500/10 p-2 rounded">
                      <Calendar className="h-5 w-5 text-blue-400" />
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-slate-200">Zeitlicher Rahmen</h3>
                      <p className="text-slate-300 mt-1">Verfügbar ab 01.09.2025</p>
                    </div>
                  </div>

                  {/* Standorte */}
                  <div className="flex items-start space-x-4">
                    <div className="bg-green-500/10 p-2 rounded">
                      <MapPin className="h-5 w-5 text-green-400" />
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-slate-200">Bevorzugte Regionen</h3>
                      <div className="mt-2 flex flex-wrap gap-2">
                        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-slate-700 text-slate-200">
                          Hannover
                        </span>
                        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-slate-700 text-slate-200">
                          Bielefeld
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Interessen */}
                  <div className="flex items-start space-x-4">
                    <div className="bg-purple-500/10 p-2 rounded">
                      <LinkedinIcon className="h-5 w-5 text-purple-400" />
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-slate-200">Interessen</h3>
                      <p className="text-slate-300 mt-1">
                        Ich interessiere mich besonders für Positionen im Bereich Business Intelligence,
                        Datenanalyse und Softwareentwicklung. Meine Bachelorarbeit würde ich gerne
                        in einem dieser Bereiche schreiben.
                      </p>
                    </div>
                  </div>

                  {/* CTA */}
                  <div className="mt-8 p-4 bg-blue-500/10 border border-blue-500/20 rounded-lg">
                    <p className="text-blue-300 font-medium">
                      ✨ Hast du ein spannendes Angebot oder möchtest du mehr über meine Projekte erfahren?
                      Kontaktiere mich gerne per E-Mail oder Telefon!
                    </p>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;