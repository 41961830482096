/**
 * Hilfsfunktionen für die Arbeit mit Plotly-Charts und Handelsdaten
 */

/**
 * Findet den Candle-Zeitstempel, der am nächsten zum angegebenen Zeitstempel liegt
 * @param {string} timestamp - Zeitstempel als String
 * @param {Array} candleData - Array von Candle-Daten
 * @returns {Object|null} - Das nächstgelegene Candle-Objekt oder null, wenn keines gefunden wurde
 */
export const findClosestCandle = (timestamp, candleData) => {
  if (!timestamp || !candleData || !Array.isArray(candleData) || candleData.length === 0) {
    return null;
  }

  const targetTime = new Date(timestamp).getTime();

  // Findet das Candle mit dem nächstgelegenen Zeitstempel
  let closestCandle = null;
  let minTimeDiff = Infinity;

  for (const candle of candleData) {
    const candleTime = new Date(candle.timestamp).getTime();
    const timeDiff = Math.abs(candleTime - targetTime);

    if (timeDiff < minTimeDiff) {
      minTimeDiff = timeDiff;
      closestCandle = candle;
    }
  }

  return closestCandle;
};

/**
 * Erstellt ein Standard-Theme für Plotly-Charts
 * @returns {Object} - Theme-Objekt mit Farben und Einstellungen
 */
export const getChartTheme = (isDarkMode = true) => {
  return {
    background: isDarkMode ? '#1e293b' : '#ffffff',
    text: isDarkMode ? '#e2e8f0' : '#1e293b',
    primary: '#3b82f6',                              // Blau
    success: '#22c55e',                              // Grün
    danger: '#ef4444',                               // Rot
    chartGrid: isDarkMode ? '#334155' : '#e2e8f0',
    longEntry: '#22c55e',                            // Grün für Long-Einstiege
    longExit: '#10b981',                             // Helleres Grün für Long-Ausstiege
    shortEntry: '#ef4444',                           // Rot für Short-Einstiege
    shortExit: '#f87171'                             // Helleres Rot für Short-Ausstiege
  };
};

/**
 * Erstellt ein Candlestick-Trace für Plotly
 * @param {Array} chartData - Array von Candle-Daten
 * @param {string} currencyId - Währungs-ID (z.B. 'BTC')
 * @param {Object} theme - Farbthema
 * @returns {Object} - Plotly-Trace für Candlestick-Chart
 */
export const createCandlestickTrace = (chartData, currencyId, theme) => {
  return {
    type: 'candlestick',
    x: chartData.map(d => d.timestamp),
    open: chartData.map(d => d.open),
    high: chartData.map(d => d.high),
    low: chartData.map(d => d.low),
    close: chartData.map(d => d.close),
    name: `${currencyId}/USD`,
    increasing: { line: { color: theme.success } },
    decreasing: { line: { color: theme.danger } },
    yaxis: 'y'
  };
};

/**
 * Erstellt ein MACD-Segment-Trace für Plotly
 * @param {Array} segment - Array von MACD-Datenpunkten in einem Segment
 * @param {boolean} isBullish - Ob das Segment bullisch ist
 * @param {Object} theme - Farbthema
 * @param {number} index - Index des Segments
 * @returns {Object} - Plotly-Trace für MACD-Segment
 */
export const createMacdSegmentTrace = (segment, isBullish, theme, index) => {
  return {
    type: 'scatter',
    x: segment.x,
    y: segment.y,
    name: isBullish ? 'MACD (Bullish)' : 'MACD (Bearish)',
    line: {
      color: isBullish ? theme.success : theme.danger,
      width: 1.5
    },
    mode: 'lines',
    hoverinfo: 'y+name',
    showlegend: index === 0 // Zeige nur einen Eintrag pro Typ in der Legende
  };
};

/**
 * Erstellt alle MACD-Segmente basierend auf den Chartdaten
 * @param {Array} chartData - Array von Chartdaten mit MACD und Signal
 * @returns {Array} - Array von MACD-Segmenten
 */
export const createMacdSegments = (chartData) => {
  if (!chartData || !Array.isArray(chartData) || chartData.length === 0) {
    return [];
  }

  const macdSegments = [];
  let currentSegment = null;
  let isBullish = false;

  // Durchlaufe die Daten und erstelle Segmente basierend auf MACD-Kreuzungen
  chartData.forEach((point, index) => {
    // Bestimme, ob der aktuelle Punkt bullisch ist (MACD über Signal)
    const currentIsBullish = point.macd >= point.macd_signal;

    // Wenn dies der erste Punkt ist oder wenn sich der Zustand geändert hat, starte ein neues Segment
    if (index === 0 || currentIsBullish !== isBullish) {
      // Wenn es bereits ein Segment gibt, beende es
      if (currentSegment) {
        macdSegments.push(currentSegment);
      }

      // Starte ein neues Segment
      currentSegment = {
        x: [point.timestamp],
        y: [point.macd],
        isBullish: currentIsBullish
      };

      // Aktualisiere den aktuellen Zustand
      isBullish = currentIsBullish;
    } else {
      // Füge dem aktuellen Segment einen Punkt hinzu
      currentSegment.x.push(point.timestamp);
      currentSegment.y.push(point.macd);
    }
  });

  // Füge das letzte Segment hinzu, wenn es existiert
  if (currentSegment) {
    macdSegments.push(currentSegment);
  }

  return macdSegments;
};