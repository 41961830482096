import apiService from './apiService';

/**
 * Service für die Berechnung von Trading-Performance-Metriken
 */
const performanceService = {
  /**
   * Berechnet alle Performance-Metriken basierend auf den Order-Daten aller Währungen
   * @returns {Promise<Object>} - Objekt mit verschiedenen Performance-Metriken
   */
  async calculatePerformanceMetrics() {
    try {
      // Orders von allen unterstützten Währungen laden
      const currencies = ['BTC', 'ETH', 'BNB', 'DOGE', 'SOL', 'XRP'];
      const allOrders = [];

      // Asynchron alle Order-Daten laden - HIER NUTZEN WIR JETZT DIE NEUE METHODE
      const orderPromises = currencies.map(currency =>
        apiService.fetchAllOrderData(currency)  // Verwende fetchAllOrderData statt fetchOrderData
          .then(orders => {
            if (orders && orders.length > 0) {
              // Währungsinformation hinzufügen
              const ordersWithCurrency = orders.map(order => ({
                ...order,
                currency
              }));
              return ordersWithCurrency;
            }
            return [];
          })
          .catch(err => {
            console.error(`Error fetching orders for ${currency}:`, err);
            return [];
          })
      );

      // Auf alle Ergebnisse warten
      const results = await Promise.all(orderPromises);

      // Ergebnisse zusammenführen
      results.forEach(orders => {
        if (orders && orders.length > 0) {
          allOrders.push(...orders);
        }
      });

      // Wenn keine Orders gefunden wurden, leeres Objekt zurückgeben
      if (allOrders.length === 0) {
        return {
          totalTrades: 0,
          winRate: 0,
          averageProfit: 0,
          averageLoss: 0,
          profitFactor: 0,
          maxDrawdown: 0
        };
      }

      // Orders nach Timestamp sortieren
      allOrders.sort((a, b) =>
        new Date(a.timestamp) - new Date(b.timestamp)
      );

      // Winning und Losing Trades separieren
      const winningTrades = allOrders.filter(order => order.realised_pnl > 0);
      const losingTrades = allOrders.filter(order => order.realised_pnl <= 0);

      // Basis-Metriken berechnen
      const totalTrades = allOrders.length;
      const winRate = (winningTrades.length / totalTrades) * 100;

      // Durchschnittlicher Profit und Verlust
      const averageProfit = winningTrades.length > 0
        ? winningTrades.reduce((sum, order) => sum + order.realised_pnl, 0) / winningTrades.length
        : 0;

      const averageLoss = losingTrades.length > 0
        ? losingTrades.reduce((sum, order) => sum + order.realised_pnl, 0) / losingTrades.length
        : 0;

      // Profit Factor berechnen
      const grossProfit = winningTrades.reduce((sum, order) => sum + order.realised_pnl, 0);
      const grossLoss = Math.abs(losingTrades.reduce((sum, order) => sum + order.realised_pnl, 0));
      const profitFactor = grossLoss > 0 ? grossProfit / grossLoss : 0;

      // Drawdown berechnen
      const initialBalance = 100; // $100 Startguthaben
      let currentBalance = initialBalance;
      let peak = initialBalance;
      let maxDrawdownPercent = 0;

      // Balanceentwicklung berechnen und maximalen Drawdown finden
      allOrders.forEach(order => {
        currentBalance += order.realised_pnl;

        // Neuen Peak aktualisieren, wenn aktueller Stand höher ist
        if (currentBalance > peak) {
          peak = currentBalance;
        }

        // Drawdown als Prozentsatz vom Peak berechnen
        const drawdown = ((peak - currentBalance) / peak) * 100;
        if (drawdown > maxDrawdownPercent) {
          maxDrawdownPercent = drawdown;
        }
      });

      return {
        totalTrades,
        winRate: parseFloat(winRate.toFixed(1)),
        averageProfit: parseFloat(averageProfit.toFixed(4)),
        averageLoss: parseFloat(averageLoss.toFixed(4)),
        profitFactor: parseFloat(profitFactor.toFixed(2)),
        maxDrawdown: parseFloat(maxDrawdownPercent.toFixed(2))
      };
    } catch (error) {
      console.error('Error calculating performance metrics:', error);
      throw error;
    }
  },

  /**
   * Gibt Beispiel-Performance-Daten zurück, falls keine echten Daten verfügbar sind
   * @returns {Object} - Beispielmetriken
   */
  getFallbackMetrics() {
    // Fallback-Werte, falls die API nicht verfügbar ist
    return {
      totalTrades: 52,
      winRate: 42.3,
      averageProfit: 2.1489,
      averageLoss: -1.2975,
      profitFactor: 0.74,
      maxDrawdown: 8.32
    };
  }
};

export default performanceService;