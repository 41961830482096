/**
 * Utilities zur Formatierung von Daten für die Anzeige
 */

/**
 * Formatiert eine Preiszahl mit Tausender-Trennzeichen und optionaler Genauigkeit
 * @param {number} price - Preis, der formatiert werden soll
 * @param {number} decimals - Anzahl der Dezimalstellen (Standard: 2)
 * @returns {string} - Formatierter Preis als String
 */
export const formatPrice = (price, decimals = 2) => {
  if (price === null || price === undefined) return "N/A";

  return price.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
};

/**
 * Formatiert ein Volumen in Millionen (M) oder Milliarden (B)
 * @param {number} volume - Volumen, das formatiert werden soll
 * @returns {string} - Formatiertes Volumen als String
 */
export const formatVolume = (volume) => {
  if (volume === null || volume === undefined) return "N/A";

  if (volume >= 1_000_000_000) {
    return (volume / 1_000_000_000).toFixed(1) + 'B';
  } else if (volume >= 1_000_000) {
    return (volume / 1_000_000).toFixed(1) + 'M';
  } else {
    return volume.toLocaleString();
  }
};

/**
 * Formatiert eine Prozentänderung mit Vorzeichen
 * @param {number} percent - Prozentänderung
 * @param {number} decimals - Anzahl der Dezimalstellen (Standard: 2)
 * @returns {string} - Formatierte Prozentänderung als String
 */
export const formatPercentage = (percent, decimals = 2) => {
  if (percent === null || percent === undefined) return "N/A";

  const sign = percent >= 0 ? '+' : '';
  return `${sign}${percent.toFixed(decimals)}%`;
};

/**
 * Formatiert ein Datum für die Anzeige
 * @param {string|Date} dateString - Datum als String oder Date-Objekt
 * @param {boolean} includeTime - Ob die Zeit mit formatiert werden soll (Standard: true)
 * @returns {string} - Formatiertes Datum als String
 */
export const formatDate = (dateString, includeTime = true) => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);

  if (isNaN(date)) return "Invalid Date";

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...(includeTime && {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    })
  };

  return date.toLocaleString(undefined, options);
};

/**
 * Formatiert einen Zeitstempel in relativer Zeit (z.B. "vor 5 Min.")
 * @param {string|Date} dateString - Datum als String oder Date-Objekt
 * @returns {string} - Relative Zeit als String
 */
export const formatRelativeTime = (dateString) => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);

  if (isNaN(date)) return "Invalid Date";

  const now = new Date();
  const diffMs = now - date;
  const diffSec = Math.floor(diffMs / 1000);

  if (diffSec < 60) return `vor ${diffSec} Sek.`;

  const diffMin = Math.floor(diffSec / 60);
  if (diffMin < 60) return `vor ${diffMin} Min.`;

  const diffHour = Math.floor(diffMin / 60);
  if (diffHour < 24) return `vor ${diffHour} Std.`;

  const diffDay = Math.floor(diffHour / 24);
  if (diffDay < 30) return `vor ${diffDay} Tagen`;

  return formatDate(date, false);
};