import { useState, useEffect } from 'react';
import apiService from '../services/apiService';
import { useCurrency } from '../contexts/CurrencyContext';

/**
 * Custom Hook für das Laden und Verwalten von Marktstatistiken (Preis, Volumen, MACD-Trend)
 * @param {number} refreshInterval - Intervall für automatische Aktualisierung in ms (Standard: 5000ms = 5s)
 * @returns {Object} - Objekt mit verschiedenen Marktstatistiken, Ladestatus und Fehler
 */
const useMarketStats = (refreshInterval = 5000) => {
  const { currency } = useCurrency();

  // Stati für verschiedene Daten
  const [currentPrice, setCurrentPrice] = useState(0);
  const [priceChange, setPriceChange] = useState(0);
  const [volume24h, setVolume24h] = useState(0);
  const [marketTrend, setMarketTrend] = useState('Loading...');
  const [trendColor, setTrendColor] = useState('text-slate-100');

  // Allgemeine Stati
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchStats = async () => {
    try {
      setIsLoading(true);

      let priceFetched = false;
      let volumeFetched = false;
      let macdFetched = false;

      // Preis laden
      try {
        const priceData = await apiService.fetchCurrentPrice(currency.id);
        setCurrentPrice(priceData.price);
        setPriceChange(priceData.percent_change);
        priceFetched = true;
      } catch (priceErr) {
        console.error('Error fetching price data:', priceErr);
        // Fallback-Werte
        const fallbackPrices = {
          'BTC': 45321.00,
          'ETH': 2850.00,
          'BNB': 580.00,
          'DOGE': 0.12,
          'SOL': 95.00,
          'XRP': 0.58
        };
        setCurrentPrice(fallbackPrices[currency.id] || 1000.00);
        setPriceChange(5.25);
      }

      // Volumen laden
      try {
        const volumeData = await apiService.fetch24hVolume(currency.id);
        setVolume24h(volumeData.volume);
        volumeFetched = true;
      } catch (volumeErr) {
        console.error('Error fetching volume data:', volumeErr);
        // Fallback-Werte basierend auf typischen Volumina
        const fallbackVolumes = {
          'BTC': 2100000000,
          'ETH': 900000000,
          'BNB': 400000000,
          'DOGE': 200000000,
          'SOL': 300000000,
          'XRP': 250000000
        };
        setVolume24h(fallbackVolumes[currency.id] || 100000000);
      }

      // MACD-Trend laden
      try {
        const macdData = await apiService.fetchMacdTrend(currency.id);
        const isBullish = macdData.macd > macdData.signal;
        setMarketTrend(isBullish ? 'Bullish' : 'Bearish');
        setTrendColor(isBullish ? 'text-green-400' : 'text-red-400');
        macdFetched = true;
      } catch (macdErr) {
        console.error('Error fetching MACD data:', macdErr);
        setMarketTrend('Unknown');
        setTrendColor('text-slate-400');
      }

      setError(null);

      // Fehler setzen, wenn keine der Anfragen erfolgreich war
      if (!priceFetched && !volumeFetched && !macdFetched) {
        throw new Error(`Could not connect to backend API for ${currency.id}`);
      }
    } catch (err) {
      console.error('Error in fetchStats:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Daten laden, wenn sich die Währung ändert
  useEffect(() => {
    fetchStats();

    // Regelmäßige Aktualisierung einrichten
    const intervalId = setInterval(fetchStats, refreshInterval);

    // Aufräumen beim Unmounten der Komponente
    return () => clearInterval(intervalId);
  }, [currency.id, refreshInterval]);

  return {
    currentPrice,
    priceChange,
    volume24h,
    marketTrend,
    trendColor,
    isLoading,
    error,
    refreshStats: fetchStats
  };
};

export default useMarketStats;