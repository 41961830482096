import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LineChart } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/common/ui/card";
import TradingChart from '../components/dashboard/TradingChart';
import TradingStats from '../components/dashboard/TradingStats';
import CurrencySelector from '../components/dashboard/CurrencySelector';
import RecentTradesTable from '../components/dashboard/RecentTradesTable';
import TradeStatsDisplay from '../components/dashboard/TradeStatsDisplay';
import { useCurrency } from '../contexts/CurrencyContext';

function Dashboard() {
  const { currencyId } = useParams();
  const { updateCurrency, availableCurrencies, currency } = useCurrency();

  // Update the currency context when the URL parameter changes
  useEffect(() => {
    if (currencyId) {
      const upperCaseId = currencyId.toUpperCase();
      const validCurrency = availableCurrencies.find(c => c.id === upperCaseId);
      if (validCurrency) {
        updateCurrency(upperCaseId);
      }
    }
  }, [currencyId, updateCurrency, availableCurrencies]);

  return (
    <div className="min-h-screen bg-slate-900">
      {/* Navigation */}
      <nav className="sticky top-14 border-b border-slate-700 bg-slate-900/80 backdrop-blur-sm z-40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <LineChart className="h-8 w-8" style={{ color: currency.color }} />
              <span className="ml-2 text-xl font-bold text-slate-100">{currency.name} Trading Dashboard</span>
            </div>
            <CurrencySelector />
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Stats Cards */}
        <TradingStats />

        {/* Chart Section */}
        <Card className="mb-8">
          <CardHeader>
            <CardTitle className="text-lg font-semibold text-slate-100">
              Market Overview
            </CardTitle>
          </CardHeader>
          <CardContent>
            <TradingChart />
          </CardContent>
        </Card>

        {/* Additional Info Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg font-semibold text-slate-100">
                Recent {currency.id} Trades
              </CardTitle>
            </CardHeader>
            <CardContent>
              <RecentTradesTable />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="text-lg font-semibold text-slate-100">
                Trading Statistics
              </CardTitle>
            </CardHeader>
            <CardContent>
              <TradeStatsDisplay />
            </CardContent>
          </Card>
        </div>
      </main>
    </div>
  );
}

export default Dashboard;