import React from 'react';
import { useCurrency } from '../../contexts/CurrencyContext';
import useOrderData from '../../hooks/useOrderData';
import { formatDate, formatPrice } from '../../utils/formatters';

const RecentTradesTable = () => {
  const { currency } = useCurrency();
  const { orderData, loading, error } = useOrderData();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-48">
        <div className="flex flex-col items-center gap-4">
          <div className="w-6 h-6 border-3 border-blue-500 border-t-transparent rounded-full animate-spin" />
          <p className="text-slate-400 text-sm">Loading trades...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-3 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400 text-sm">
        {error}
      </div>
    );
  }

  if (!orderData || orderData.length === 0) {
    return <div className="text-slate-400">No recent trades for {currency.id}</div>;
  }

  // Sort by timestamp in descending order (newest first)
  const sortedOrders = [...orderData].sort((a, b) =>
    new Date(b.timestamp_entry) - new Date(a.timestamp_entry)
  );

  return (
    <div className="overflow-x-auto">
      <div className="max-h-[300px] overflow-y-auto">
        <table className="w-full border-collapse">
          <thead className="sticky top-0 bg-slate-800">
            <tr>
              <th className="px-3 py-2 text-left text-xs font-medium text-slate-300 border-b border-slate-700">Type</th>
              <th className="px-3 py-2 text-left text-xs font-medium text-slate-300 border-b border-slate-700">Entry</th>
              <th className="px-3 py-2 text-left text-xs font-medium text-slate-300 border-b border-slate-700">Exit</th>
              <th className="px-3 py-2 text-right text-xs font-medium text-slate-300 border-b border-slate-700">PnL</th>
              <th className="px-3 py-2 text-right text-xs font-medium text-slate-300 border-b border-slate-700">%</th>
            </tr>
          </thead>
          <tbody>
            {sortedOrders.map((order, index) => {
              // Calculate the percentage gain/loss
              const pnlPercent = (order.realised_pnl / order.value) * 100;
              // Determine if this was a profitable trade
              const isProfitable = order.realised_pnl > 0;

              return (
                <tr key={index} className="hover:bg-slate-700/50">
                  <td className="px-3 py-2 text-xs whitespace-nowrap border-b border-slate-700">
                    <div className="flex items-center">
                      <span className={`w-2 h-2 rounded-full mr-2 ${
                        order.order_type === 'long' ? 'bg-green-500' : 'bg-red-500'
                      }`}></span>
                      <span className="font-medium capitalize">{order.order_type}</span>
                    </div>
                  </td>
                  <td className="px-3 py-2 text-xs whitespace-nowrap border-b border-slate-700">
                    <div className="font-mono">${formatPrice(order.entry)}</div>
                    <div className="text-xs text-slate-400">{formatDate(order.timestamp_entry)}</div>
                  </td>
                  <td className="px-3 py-2 text-xs whitespace-nowrap border-b border-slate-700">
                    <div className="font-mono">${order.timestamp_exit ? formatPrice(order.value / order.quantity) : '-'}</div>
                    <div className="text-xs text-slate-400">{order.timestamp_exit ? formatDate(order.timestamp_exit) : 'Open'}</div>
                  </td>
                  <td className={`px-3 py-2 text-xs whitespace-nowrap text-right border-b border-slate-700 font-medium ${
                    isProfitable ? 'text-green-400' : 'text-red-400'
                  }`}>
                    {isProfitable ? '+' : ''}{order.realised_pnl.toFixed(4)}
                  </td>
                  <td className={`px-3 py-2 text-xs whitespace-nowrap text-right border-b border-slate-700 font-medium ${
                    isProfitable ? 'text-green-400' : 'text-red-400'
                  }`}>
                    {isProfitable ? '+' : ''}{pnlPercent.toFixed(2)}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="text-xs text-slate-400 mt-2 text-right">
        {orderData.length} trade{orderData.length !== 1 ? 's' : ''}
      </div>
    </div>
  );
};

export default RecentTradesTable;