import React from 'react';
import { useCurrency, AVAILABLE_CURRENCIES } from '../../contexts/CurrencyContext';
import { Link } from 'react-router-dom';

const CurrencySelector = () => {
  const { currency, updateCurrency } = useCurrency();

  return (
    <div className="flex items-center space-x-2">
      <span className="text-slate-300 text-sm font-medium">Währung:</span>
      <div className="flex items-center space-x-1">
        {AVAILABLE_CURRENCIES.map((curr) => (
          <Link
            key={curr.id}
            to={`/dashboard/${curr.id.toLowerCase()}`}
            className={`px-3 py-1 rounded-md text-sm font-medium transition-colors ${
              currency.id === curr.id
                ? 'bg-blue-500 text-white'
                : 'text-slate-300 hover:bg-slate-700 hover:text-white'
            }`}
            onClick={() => updateCurrency(curr.id)}
          >
            {curr.id}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CurrencySelector;