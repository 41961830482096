import React from 'react';
import Plot from 'react-plotly.js';
import { useCurrency } from '../../contexts/CurrencyContext';
import useTradingData from '../../hooks/useTradingData';
import useOrderData from '../../hooks/useOrderData';
import {
  formatDate,
  formatPrice
} from '../../utils/formatters';
import {
  findClosestCandle,
  getChartTheme,
  createCandlestickTrace,
  createMacdSegments,
  createMacdSegmentTrace
} from '../../utils/chartHelpers';

const TradingChart = () => {
  const { currency } = useCurrency();
  const { chartData, loading, error, lastUpdate } = useTradingData();
  const { orderData } = useOrderData();

  // Theme für das Chart
  const theme = getChartTheme();

  if (loading) {
    return (
      <div className="flex items-center justify-center p-12">
        <div className="flex flex-col items-center gap-4">
          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
          <p className="text-slate-200">Loading trading data for {currency.name}...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-500">
        {error}
      </div>
    );
  }

  if (!chartData) return null;

  // Chart configuration
  const commonChartConfig = {
    displayModeBar: true,
    displaylogo: false,
    responsive: true,
    modeBarButtonsToRemove: ['lasso2d', 'select2d'],
    toImageButtonOptions: {
      format: 'png',
      filename: `${currency.id}_trading_chart`,
      height: 1200,
      width: 1800,
      scale: 2
    }
  };

  // Candlestick Chart Data
  const candlestickTrace = createCandlestickTrace(chartData, currency.id, theme);

  // EMA Traces
  const ema20Trace = {
    type: 'scatter',
    x: chartData.map(d => d.timestamp),
    y: chartData.map(d => d.ema20),
    name: 'EMA 20',
    line: { color: '#60a5fa', width: 1.5 },
    hoverinfo: 'y+name'
  };

  const ema200Trace = {
    type: 'scatter',
    x: chartData.map(d => d.timestamp),
    y: chartData.map(d => d.ema200),
    name: 'EMA 200',
    line: { color: '#c084fc', width: 1.5 },
    hoverinfo: 'y+name'
  };

  // Create entry and exit point traces
  const longEntries = {
    type: 'scatter',
    x: orderData.filter(order => order.order_type === 'long').map(order => order.timestamp_entry),
    y: orderData.filter(order => order.order_type === 'long').map(order => order.entry),
    mode: 'markers',
    name: 'Long Entry',
    marker: {
      color: theme.longEntry,
      size: 10,
      symbol: 'triangle-up',
      line: { color: 'white', width: 1 }
    },
    hoverinfo: 'text',
    hovertext: orderData.filter(order => order.order_type === 'long').map(order =>
      `Long Entry<br>Price: $${formatPrice(order.entry)}<br>Time: ${formatDate(order.timestamp_entry)}`
    )
  };

  const longExits = {
    type: 'scatter',
    x: orderData.filter(order => order.order_type === 'long').map(order => order.timestamp_exit),
    y: orderData.filter(order => order.order_type === 'long').map(order => {
      // Find the closest candle to the exit timestamp
      const closestCandle = findClosestCandle(order.timestamp_exit, chartData);
      return closestCandle ? closestCandle.close : null;
    }),
    mode: 'markers',
    name: 'Long Exit',
    marker: {
      color: theme.longExit,
      size: 10,
      symbol: 'triangle-down',
      line: { color: 'white', width: 1 }
    },
    hoverinfo: 'text',
    hovertext: orderData.filter(order => order.order_type === 'long').map((order, index) => {
      const closestCandle = findClosestCandle(order.timestamp_exit, chartData);
      const exitPrice = closestCandle ? closestCandle.close : 0;
      const pnl = order.realised_pnl;
      return `Long Exit<br>Price: $${formatPrice(exitPrice)}<br>PnL: ${pnl >= 0 ? '+' : ''}${pnl.toFixed(4)} (${((pnl / order.value) * 100).toFixed(2)}%)<br>Time: ${formatDate(order.timestamp_exit)}`;
    })
  };

  const shortEntries = {
    type: 'scatter',
    x: orderData.filter(order => order.order_type === 'short').map(order => order.timestamp_entry),
    y: orderData.filter(order => order.order_type === 'short').map(order => order.entry),
    mode: 'markers',
    name: 'Short Entry',
    marker: {
      color: theme.shortEntry,
      size: 10,
      symbol: 'triangle-down',
      line: { color: 'white', width: 1 }
    },
    hoverinfo: 'text',
    hovertext: orderData.filter(order => order.order_type === 'short').map(order =>
      `Short Entry<br>Price: $${formatPrice(order.entry)}<br>Time: ${formatDate(order.timestamp_entry)}`
    )
  };

  const shortExits = {
    type: 'scatter',
    x: orderData.filter(order => order.order_type === 'short').map(order => order.timestamp_exit),
    y: orderData.filter(order => order.order_type === 'short').map(order => {
      // Find the closest candle to the exit timestamp
      const closestCandle = findClosestCandle(order.timestamp_exit, chartData);
      return closestCandle ? closestCandle.close : null;
    }),
    mode: 'markers',
    name: 'Short Exit',
    marker: {
      color: theme.shortExit,
      size: 10,
      symbol: 'triangle-up',
      line: { color: 'white', width: 1 }
    },
    hoverinfo: 'text',
    hovertext: orderData.filter(order => order.order_type === 'short').map((order, index) => {
      const closestCandle = findClosestCandle(order.timestamp_exit, chartData);
      const exitPrice = closestCandle ? closestCandle.close : 0;
      const pnl = order.realised_pnl;
      return `Short Exit<br>Price: $${formatPrice(exitPrice)}<br>PnL: ${pnl >= 0 ? '+' : ''}${pnl.toFixed(4)} (${((pnl / order.value) * 100).toFixed(2)}%)<br>Time: ${formatDate(order.timestamp_exit)}`;
    })
  };

  // MACD-Segmente erstellen und Traces generieren
  const macdSegments = createMacdSegments(chartData);
  const macdTraces = macdSegments.map((segment, index) =>
    createMacdSegmentTrace(segment, segment.isBullish, theme, index)
  );

  // Signal-Linie für MACD
  const macdSignalTrace = {
    type: 'scatter',
    x: chartData.map(d => d.timestamp),
    y: chartData.map(d => d.macd_signal),
    name: 'Signal Line',
    line: { color: theme.primary, width: 1.5 },
    hoverinfo: 'y+name'
  };

  // Common Layout Properties
  const commonLayoutProps = {
    plot_bgcolor: theme.background,
    paper_bgcolor: theme.background,
    font: {
      color: theme.text,
      family: 'Inter, system-ui, sans-serif'
    },
    showlegend: true,
    legend: {
      bgcolor: 'rgba(0,0,0,0)',
      bordercolor: 'rgba(0,0,0,0)',
      font: { size: 11 }
    },
    xaxis: {
      rangeslider: { visible: false },
      type: 'date',
      gridcolor: theme.chartGrid,
      linecolor: theme.chartGrid,
      showgrid: true
    }
  };

  const priceLayout = {
    ...commonLayoutProps,
    title: {
      text: `${currency.name} (${currency.id}) Price Chart (15min) with Trade Entries/Exits`,
      font: { size: 20 }
    },
    height: 600,
    autosize: true,
    yaxis: {
      title: 'Price (USD)',
      gridcolor: theme.chartGrid,
      linecolor: theme.chartGrid,
      tickformat: ',.0f',
      showgrid: true
    },
    margin: { t: 50, l: 60, r: 30, b: 30 }
  };

  const macdLayout = {
    ...commonLayoutProps,
    title: {
      text: 'MACD Indicator',
      font: { size: 16 }
    },
    height: 300,
    autosize: true,
    yaxis: {
      title: 'MACD',
      gridcolor: theme.chartGrid,
      linecolor: theme.chartGrid,
      showgrid: true
    },
    margin: { t: 40, l: 60, r: 30, b: 30 }
  };

  return (
    <div className="space-y-6">
      <Plot
        data={[
          candlestickTrace,
          ema20Trace,
          ema200Trace,
          longEntries,
          longExits,
          shortEntries,
          shortExits
        ]}
        layout={priceLayout}
        config={commonChartConfig}
        className="w-full h-[600px]"
        useResizeHandler={true}
        style={{width: '100%'}}
      />

      <Plot
        data={[...macdTraces, macdSignalTrace]}
        layout={macdLayout}
        config={commonChartConfig}
        className="w-full h-[300px]"
        useResizeHandler={true}
        style={{width: '100%'}}
      />

      <div className="text-sm text-slate-300 flex items-center justify-between">
        <div>Data updated: {formatDate(lastUpdate)}</div>
        {orderData.length > 0 && (
          <div className="text-slate-300">
            Showing {orderData.length} trade{orderData.length > 1 ? 's' : ''}
          </div>
        )}
      </div>
    </div>
  );
};

export default TradingChart;