import React from 'react';
import { Activity, TrendingUp } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "../common/ui/card";
import { useCurrency } from '../../contexts/CurrencyContext';
import useMarketStats from '../../hooks/useMarketStats';
import { formatVolume, formatPrice, formatPercentage } from '../../utils/formatters';

const TradingStats = () => {
  const { currency } = useCurrency();
  const {
    currentPrice,
    priceChange,
    volume24h,
    marketTrend,
    trendColor,
    isLoading,
    error,
    refreshStats
  } = useMarketStats();

  if (error) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <Card className="border-red-500/20 col-span-1 md:col-span-3">
          <CardContent className="p-6">
            <div className="text-red-400 mb-2">Error loading data: {error}</div>
            <div className="text-slate-300 text-sm">
              <p className="mb-2">Possible solutions:</p>
              <ul className="list-disc pl-5 space-y-1">
                <li>Ensure the FastAPI server is running at localhost:8000</li>
                <li>Check if there are any CORS issues in the browser console</li>
                <li>Verify the API endpoints for {currency.id} are correctly implemented</li>
              </ul>
              <button
                onClick={() => refreshStats()}
                className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md text-sm"
              >
                Retry Connection
              </button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
      {/* Current Price Card */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium text-slate-200">
            Current {currency.id} Price
          </CardTitle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-blue-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="animate-pulse">
              <div className="h-7 bg-slate-700 rounded w-1/2 mb-2"></div>
              <div className="h-4 bg-slate-700 rounded w-1/3"></div>
            </div>
          ) : (
            <>
              <div className="text-2xl font-bold text-slate-100">
                ${formatPrice(currentPrice)}
              </div>
              <p className={`text-xs ${priceChange >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                {formatPercentage(priceChange)} from last period
              </p>
            </>
          )}
        </CardContent>
      </Card>

      {/* 24h Volume Card */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium text-slate-200">
            {currency.id} Spot 24h Volume
          </CardTitle>
          <Activity className="h-4 w-4 text-blue-500" />
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="animate-pulse">
              <div className="h-7 bg-slate-700 rounded w-1/2 mb-2"></div>
              <div className="h-4 bg-slate-700 rounded w-1/3"></div>
            </div>
          ) : (
            <>
              <div className="text-2xl font-bold text-slate-100">
                ${formatVolume(volume24h)}
              </div>
              <p className="text-xs text-slate-400">from Binance</p>
            </>
          )}
        </CardContent>
      </Card>

      {/* Market Trend Card */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium text-slate-200">
            Market Trend
          </CardTitle>
          <TrendingUp className="h-4 w-4 text-blue-500" />
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="animate-pulse">
              <div className="h-7 bg-slate-700 rounded w-1/2 mb-2"></div>
              <div className="h-4 bg-slate-700 rounded w-1/3"></div>
            </div>
          ) : (
            <>
              <div className={`text-2xl font-bold ${trendColor}`}>
                {marketTrend}
              </div>
              <p className="text-xs text-slate-400">Based on MACD</p>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default TradingStats;;