import { useState, useEffect } from 'react';
import apiService from '../services/apiService';
import { useCurrency } from '../contexts/CurrencyContext';

/**
 * Custom Hook für das Laden und Verwalten von Order-Daten
 * @param {Date} filterDate - Datum, ab dem Orders gefiltert werden sollen (Standard: 13. Februar 2025)
 * @param {number} refreshInterval - Intervall für automatische Aktualisierung in ms (Standard: 120000ms = 2min)
 * @returns {Object} - Objekt mit Daten, Ladestatus, Fehler und einer manuellen Aktualisierungsfunktion
 */
const useOrderData = (
  filterDate = new Date('2025-02-13T00:00:00'),
  refreshInterval = 120000
) => {
  const { currency } = useCurrency();
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchOrderData = async () => {
    try {
      setLoading(true);
      const orders = await apiService.fetchOrderData(currency.id);

      // Die Filterung erfolgt jetzt schon im Backend (apiService.js),
      // daher ist die folgende Filterung optional, falls du die Backend-Änderung nicht gemacht hast
      const filteredOrders = orders.filter(order => {
        const entryDate = new Date(order.timestamp_entry);
        return entryDate > filterDate;
      });

      setOrderData(filteredOrders);
      setError(null);
    } catch (err) {
      console.error(`Error fetching order data for ${currency.id}:`, err);
      setError(`Failed to fetch order data for ${currency.id}: ${err.message}`);
      setOrderData([]);
    } finally {
      setLoading(false);
    }
  };

  // Daten laden, wenn sich die Währung ändert
  useEffect(() => {
    fetchOrderData();

    // Regelmäßige Aktualisierung einrichten, wenn ein Intervall angegeben ist
    let intervalId;
    if (refreshInterval > 0) {
      intervalId = setInterval(fetchOrderData, refreshInterval);
    }

    // Aufräumen beim Unmounten der Komponente
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [currency.id, refreshInterval]);

  return {
    orderData,
    loading,
    error,
    refreshData: fetchOrderData
  };
};

export default useOrderData;