import { useState, useEffect } from 'react';
import performanceService from '../services/performanceService';

/**
 * Custom Hook für das Laden und Verwalten von Performance-Metriken
 * @param {number} refreshInterval - Intervall für automatische Aktualisierung in ms (Standard: 300000ms = 5min)
 * @returns {Object} - Objekt mit Performance-Metriken, Ladestatus und Fehler
 */
const usePerformanceMetrics = (refreshInterval = 300000) => {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchMetrics = async () => {
    try {
      setLoading(true);
      const performanceMetrics = await performanceService.calculatePerformanceMetrics();
      setMetrics(performanceMetrics);
      setError(null);
    } catch (err) {
      console.error('Error fetching performance metrics:', err);
      setError('Failed to load performance metrics. Using fallback data.');

      // Fallback-Metriken verwenden
      setMetrics(performanceService.getFallbackMetrics());
    } finally {
      setLoading(false);
    }
  };

  // Metriken beim ersten Laden abrufen
  useEffect(() => {
    fetchMetrics();

    // Regelmäßige Aktualisierung einrichten, wenn ein Intervall angegeben ist
    let intervalId;
    if (refreshInterval > 0) {
      intervalId = setInterval(fetchMetrics, refreshInterval);
    }

    // Aufräumen beim Unmounten der Komponente
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [refreshInterval]);

  return {
    metrics,
    loading,
    error,
    refreshMetrics: fetchMetrics
  };
};

export default usePerformanceMetrics;