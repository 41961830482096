import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/common/ui/card";
import {
  ArrowRight,
  LineChart,
  TrendingUp,
  BarChart4,
  CheckCircle,
  XCircle,
  Calendar,
  Clock,
  DollarSign,
  Zap,
  ClipboardList,
  ChevronRight,
  RefreshCw
} from 'lucide-react';
import usePerformanceMetrics from '../hooks/usePerformanceMetrics';

const TradingBotProject = () => {
  // Performance-Metriken laden
  const { metrics, loading, error, refreshMetrics } = usePerformanceMetrics();

  return (
    <div className="min-h-screen bg-slate-900">
      {/* Hero Section */}
      <section className="py-12 border-b border-slate-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center justify-between gap-8">
            <div className="flex-1">
              <h1 className="text-3xl font-bold text-slate-100 mb-4">
                Trading Bot Projekt
              </h1>
              <p className="text-slate-300 mb-6 text-lg">
                Ein vollautomatisierter Tradingbot, der mit Python entwickelt wurde und
                mehrere Handelsstrategien und Performance-Tracking bietet.
              </p>
              <Link
                to="/dashboard"
                className="inline-flex items-center bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition"
              >
                View Live Dashboard
                <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
            </div>
            <div className="w-full max-w-md">
              <svg
                viewBox="0 0 500 300"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-auto"
              >
                {/* Bot Visualization */}
                <rect x="50" y="50" width="400" height="200" rx="10" fill="#1e293b" stroke="#3b82f6" strokeWidth="2" />

                {/* Charts & Data */}
                <rect x="70" y="70" width="160" height="80" rx="5" fill="#111827" stroke="#60a5fa" strokeWidth="1" />
                <polyline points="80,120 95,105 110,115 125,90 140,100 155,85 170,95 185,80 200,110" fill="none" stroke="#22c55e" strokeWidth="2" />

                {/* Bot Brain */}
                <circle cx="310" cy="110" r="40" fill="#111827" stroke="#3b82f6" strokeWidth="1" />
                <circle cx="310" cy="110" r="30" fill="#1e40af" fillOpacity="0.3" />
                <circle cx="310" cy="110" r="20" fill="#3b82f6" fillOpacity="0.4" />
                <circle cx="310" cy="110" r="10" fill="#60a5fa" fillOpacity="0.6" />

                {/* Connection Lines */}
                <line x1="230" y1="110" x2="270" y2="110" stroke="#3b82f6" strokeWidth="2" strokeDasharray="5,5" />

                {/* Output/Orders */}
                <rect x="70" y="170" width="360" height="60" rx="5" fill="#111827" stroke="#60a5fa" strokeWidth="1" />
                <text x="90" y="200" fill="#22c55e" fontFamily="monospace" fontSize="12">BUY: 0.01 BTC @ $45,321.00</text>
                <text x="90" y="220" fill="#ef4444" fontFamily="monospace" fontSize="12">SELL: 0.01 BTC @ $46,850.00</text>
                <text x="270" y="200" fill="#22c55e" fontFamily="monospace" fontSize="12">PROFIT: +$152.90</text>
                <text x="270" y="220" fill="#60a5fa" fontFamily="monospace" fontSize="12">ROI: +3.37%</text>
              </svg>
            </div>
          </div>
        </div>
      </section>

      {/* Project Overview */}
      <section className="py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-slate-100 mb-8">
            Projekt Übersicht
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
            {/* Stats cards */}
            <Card>
              <CardHeader className="flex flex-row items-center justify-between pb-2">
                <CardTitle className="text-sm font-medium text-slate-200">
                  Trading Strategien
                </CardTitle>
                <TrendingUp className="h-4 w-4 text-blue-500"/>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold text-slate-100">1</div>
                <p className="text-xs text-slate-400"></p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between pb-2">
                <CardTitle className="text-sm font-medium text-slate-200">
                  Erfolgsrate
                </CardTitle>
                <BarChart4 className="h-4 w-4 text-blue-500"/>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold text-slate-100">
                  {loading ? (
                    <span className="text-slate-500">Lädt...</span>
                  ) : (
                    <span>{metrics?.winRate || 0}%</span>
                  )}
                </div>
                <p className="text-xs text-slate-400">profitable Trades</p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between pb-2">
                <CardTitle className="text-sm font-medium text-slate-200">
                  Handelsdauer
                </CardTitle>
                <Calendar className="h-4 w-4 text-blue-500"/>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold text-slate-100">2 Monate</div>
                <p className="text-xs text-slate-400">in Betrieb</p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between pb-2">
                <CardTitle className="text-sm font-medium text-slate-200">
                  Response Time
                </CardTitle>
                <Clock className="h-4 w-4 text-blue-500"/>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold text-slate-100">~0.4s</div>
                <p className="text-xs text-slate-400">Durchschnittliche Ausführung</p>
              </CardContent>
            </Card>
          </div>

          {/* Description */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
            <Card>
              <CardHeader>
                <CardTitle className="text-lg font-semibold text-slate-100">
                  Order Simulation
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-slate-300 mb-4">
                  Der Trading-Bot simuliert Orders auf der Grundlage von Echtzeit-Marktdaten und verwendet eine Kombination
                  aus technischen Indikatoren, um potenzielle Entries und Exits zu ermitteln. Jeder Handel wird
                  mit detaillierten Leistungsmetriken protokolliert.
                </p>
                <p className="text-slate-300">
                  Orders können entweder mit Echtgeld im Markt, im Testnet mit virtueller Währung, oder simuliert werden.
                  Ich habe mich beim Testing für das Simulieren entschieden, da Echtgeld erst nach Feststellung von Profitabilität eingesetzt werden sollte.
                  Die Testnets von Binance und Bitget sind voll funktionsfähige Märkte und nicht einfach nur eine isolierte Kopie der Echtzeitkurse mit Orderfunktionen, daher eignen sie sich nicht für das Testen, denn die Kurse weichen extrem von den Realkursen ab.
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="text-lg font-semibold text-slate-100 flex items-center justify-between">
                  <span>Performance Tracking</span>
                  {loading ? (
                    <RefreshCw className="h-4 w-4 text-slate-400 animate-spin" />
                  ) : (
                    <RefreshCw
                      className="h-4 w-4 text-slate-400 hover:text-blue-400 cursor-pointer transition-colors"
                      onClick={refreshMetrics}
                    />
                  )}
                </CardTitle>
              </CardHeader>
              <CardContent>
                {error && (
                  <div className="text-amber-400 text-sm mb-3 bg-amber-400/10 p-2 rounded-md">
                    {error}
                  </div>
                )}
                <div className="space-y-2 mb-4">
                  <div className="flex justify-between">
                    <span className="text-slate-300">Total Trades:</span>
                    <span className="text-slate-100 font-medium">
                      {loading ? <span className="text-slate-500">Lädt...</span> : metrics?.totalTrades || '0'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-slate-300">Win Rate:</span>
                    <span className={`font-medium ${metrics?.winRate > 50 ? 'text-green-400' : 'text-red-400'}`}>
                      {loading ? <span className="text-slate-500">Lädt...</span> : `${metrics?.winRate || 0}%`}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-slate-300">Average Profit:</span>
                    <span className="text-green-400 font-medium">
                      {loading ? <span className="text-slate-500">Lädt...</span> : `$${metrics?.averageProfit?.toFixed(4) || '0.0000'}`}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-slate-300">Average Loss:</span>
                    <span className="text-red-400 font-medium">
                      {loading ? <span className="text-slate-500">Lädt...</span> : `$${metrics?.averageLoss?.toFixed(4) || '-0.0000'}`}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-slate-300">Profit Factor:</span>
                    <span className={`font-medium ${metrics?.profitFactor >= 1 ? 'text-green-400' : 'text-slate-100'}`}>
                      {loading ? <span className="text-slate-500">Lädt...</span> : metrics?.profitFactor?.toFixed(2) || '0.00'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-slate-300">Max Drawdown:</span>
                    <span className="text-red-400 font-medium">
                      {loading ? <span className="text-slate-500">Lädt...</span> : `${metrics?.maxDrawdown?.toFixed(2) || '0.00'}%`}
                    </span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Pros and Cons */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <Card className="border-green-500/20">
              <CardHeader className="pb-3">
                <div className="flex items-center gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <CardTitle className="text-lg font-semibold text-slate-100">
                    Vorteile
                  </CardTitle>
                </div>
              </CardHeader>
              <CardContent>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <CheckCircle className="h-4 w-4 text-green-500 shrink-0 mt-0.5" />
                    <span className="text-slate-300">24/7 automatisierter Handel</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="h-4 w-4 text-green-500 shrink-0 mt-0.5" />
                    <span className="text-slate-300">sammeln von zahlreichen Daten zur Auswertung einer Strategie</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="h-4 w-4 text-green-500 shrink-0 mt-0.5" />
                    <span className="text-slate-300">keine emotionalen Trades</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="h-4 w-4 text-green-500 shrink-0 mt-0.5" />
                    <span className="text-slate-300">höhere Handelsfrequenz</span>
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card className="border-red-500/20">
              <CardHeader className="pb-3">
                <div className="flex items-center gap-2">
                  <XCircle className="h-5 w-5 text-red-500" />
                  <CardTitle className="text-lg font-semibold text-slate-100">
                    Nachteile
                  </CardTitle>
                </div>
              </CardHeader>
              <CardContent>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <XCircle className="h-4 w-4 text-red-500 shrink-0 mt-0.5" />
                    <span className="text-slate-300">hoher Aufwand der Implementierung</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <XCircle className="h-4 w-4 text-red-500 shrink-0 mt-0.5" />
                    <span className="text-slate-300">Indikatoren müssen teilweise selbst berechnet werden (keine User API bei Tradingview)</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <XCircle className="h-4 w-4 text-red-500 shrink-0 mt-0.5" />
                    <span className="text-slate-300">benötigt viel Überwachung und Wartung</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <XCircle className="h-4 w-4 text-red-500 shrink-0 mt-0.5" />
                    <span className="text-slate-300">anfällig für Black Swan Events</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* Trading Strategies */}
      <section className="py-12 bg-slate-800/30 border-t border-slate-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-slate-100 mb-8">
            Trading Strategies
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* MACD Strategy Card */}
            <Card className="group hover:border-blue-500/50 transition-all duration-300">
              <CardHeader>
                <CardTitle className="text-lg font-semibold text-slate-100 flex items-center gap-2">
                  <LineChart className="h-5 w-5 text-blue-500" />
                  MACD Strategy
                </CardTitle>
                <CardDescription className="text-slate-400">
                  Momentum indicator-based strategy
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-slate-300 mb-4">
                  Utilizes the Moving Average Convergence Divergence (MACD) indicator to identify
                  momentum shifts and potential trend reversals in the market.
                </p>
                <div className="space-y-2 mb-4">
                  <div className="flex justify-between">
                    <span className="text-slate-400">Win Rate:</span>
                    <span className={`${metrics?.winRate > 50 ? 'text-green-400' : 'text-red-400'}`}>
                      {loading ? <span className="text-slate-500">Lädt...</span> : `${metrics?.winRate || 0}%`}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-slate-400">Profit Factor:</span>
                    <span className="text-slate-300">
                      {loading ? <span className="text-slate-500">Lädt...</span> : metrics?.profitFactor?.toFixed(2) || '0'}
                    </span>
                  </div>
                </div>
                <Link
                  to="/dashboard"
                  className="inline-flex items-center text-blue-400 hover:text-blue-300 group-hover:translate-x-1 transition-transform"
                >
                  View Strategy Details
                  <ArrowRight className="ml-2 h-4 w-4" />
                </Link>
              </CardContent>
            </Card>

            {/* Placeholder for future strategies */}
            <Card className="bg-slate-800/50 border-dashed border-slate-700">
              <CardHeader>
                <CardTitle className="text-lg font-semibold text-slate-400 flex items-center gap-2">
                  <Zap className="h-5 w-5 text-orange-500/70" />
                  RSI Strategy
                </CardTitle>
                <CardDescription className="text-slate-500">
                  Relative Strength Index strategy
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-slate-400 mb-4">
                  Uses the Relative Strength Index to identify overbought and oversold market
                  conditions for potential entry and exit points.
                </p>
                <div className="space-y-2 mb-4">
                  <div className="flex justify-between">
                    <span className="text-slate-500">Win Rate:</span>
                    <span className="text-slate-400">Coming soon</span>
                  </div>
                </div>
                <span className="inline-flex items-center text-slate-500">
                  In Development
                  <Clock className="ml-2 h-4 w-4" />
                </span>
              </CardContent>
            </Card>

            <Card className="bg-slate-800/50 border-dashed border-slate-700">
              <CardHeader>
                <CardTitle className="text-lg font-semibold text-slate-400 flex items-center gap-2">
                  <DollarSign className="h-5 w-5 text-green-500/70" />
                  Bollinger Bands Strategy
                </CardTitle>
                <CardDescription className="text-slate-500">
                  Volatility-based trading approach
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-slate-400 mb-4">
                  Leverages Bollinger Bands to identify price volatility and potential
                  breakouts or mean reversions in the market.
                </p>
                <div className="space-y-2 mb-4">
                  <div className="flex justify-between">
                    <span className="text-slate-500">Win Rate:</span>
                    <span className="text-slate-400">Coming soon</span>
                  </div>
                </div>
                <span className="inline-flex items-center text-slate-500">
                  In Development
                  <Clock className="ml-2 h-4 w-4" />
                </span>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* NEW SECTION: Trading Overview Image */}
      <section className="py-12 border-t border-slate-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-slate-100 mb-8">
            Trading System Überblick
          </h2>

          <Card className="mb-6">
            <CardContent className="pt-6">
              <div className="flex justify-center">
                <img
                  src="/images/TradingUeberblick.png"
                  alt="Trading System Überblick"
                  className="max-w-full rounded-lg shadow-lg border border-slate-700"
                />
              </div>
            </CardContent>
          </Card>

          <p className="text-slate-300 text-center mt-4">
            Schematische Darstellung der Systemarchitektur und Datenflüsse des Trading Bot Projekts
          </p>
        </div>
      </section>

      {/* NEW SECTION: Future Enhancements */}
      <section className="py-12 bg-slate-800/30 border-t border-slate-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-slate-100 mb-8">
            Zukünftige Ergänzungen
          </h2>

          <Card>
            <CardHeader>
              <div className="flex items-center gap-2">
                <ClipboardList className="h-5 w-5 text-blue-500" />
                <CardTitle className="text-lg font-semibold text-slate-100">
                  Offene Aufgaben
                </CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <ul className="space-y-4">
                <li className="flex items-start gap-3">
                  <ChevronRight className="h-5 w-5 text-blue-500 shrink-0 mt-0.5" />
                  <div>
                    <h3 className="text-slate-200 font-medium">Daten sammeln</h3>
                    <p className="text-slate-400 mt-1">MACD Strategie Orderdaten sammeln lassen</p>
                  </div>
                </li>

                <li className="flex items-start gap-3">
                  <ChevronRight className="h-5 w-5 text-blue-500 shrink-0 mt-0.5" />
                  <div>
                    <h3 className="text-slate-200 font-medium">Orderausführung implementieren</h3>
                    <p className="text-slate-400 mt-1">Bei Erfolg der simulierten Orders, echte Orderausführung implementieren</p>
                  </div>
                </li>

                <li className="flex items-start gap-3">
                  <ChevronRight className="h-5 w-5 text-blue-500 shrink-0 mt-0.5" />
                  <div>
                    <h3 className="text-slate-200 font-medium">Strategie optimieren</h3>
                    <p className="text-slate-400 mt-1">Bedingungen für Orders anpassen und gegenenfalls verbessern</p>
                  </div>
                </li>

                <li className="flex items-start gap-3">
                  <ChevronRight className="h-5 w-5 text-blue-500 shrink-0 mt-0.5" />
                  <div>
                    <h3 className="text-slate-200 font-medium">Echtzeit-Benachrichtigungen</h3>
                    <p className="text-slate-400 mt-1">regelmäßige alerts und Statusmeldungen</p>
                  </div>
                </li>

                <li className="flex items-start gap-3">
                  <ChevronRight className="h-5 w-5 text-blue-500 shrink-0 mt-0.5" />
                  <div>
                    <h3 className="text-slate-200 font-medium">Dashboard Erweiterungen</h3>
                    <p className="text-slate-400 mt-1">Detaillierte Performance-Metriken und Trade-History Visualisierungen hinzufügen</p>
                  </div>
                </li>
              </ul>
            </CardContent>
          </Card>
        </div>
      </section>
    </div>
  );
};

export default TradingBotProject;