import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { CurrencyProvider } from './contexts/CurrencyContext';

// Importiere Komponenten
import LandingPage from './pages/LandingPage';
import TradingDashboard from './pages/Dashboard';
import TradingBotProject from './pages/TradingBotProject';
import Contact from './pages/Contact';

function App() {
  return (
    <CurrencyProvider>
      <Router>
        <div className="min-h-screen bg-slate-900">
          {/* Hauptnavigation */}
          <nav className="bg-gray-900 text-white p-4 top-0 sticky z-50">
            <div className="max-w-7xl mx-auto flex gap-4">
              <Link to="/" className="hover:text-blue-400">Home</Link>
              <Link to="/trading-bot" className="hover:text-blue-400">Trading Bot</Link>
              <Link to="/dashboard/btc" className="hover:text-blue-400">MACD Dashboard</Link>
              <Link to="/contact" className="hover:text-blue-400">Kontakt</Link>
            </div>
          </nav>

          {/* Content-Bereich */}
          <div className="flex-1">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/trading-bot" element={<TradingBotProject />} />
              <Route path="/dashboard" element={<Navigate to="/dashboard/btc" replace />} />
              <Route path="/dashboard/:currencyId" element={<TradingDashboard />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </div>
      </Router>
    </CurrencyProvider>
  );
}

export default App;
